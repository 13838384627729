<template>
  <div class="row" v-if="Object.keys(hub).length !== 0">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-weight-bold">Hub Name</label>
                <p>{{ hub.name }}</p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-weight-bold">Phone</label>
                <p>{{ hub.phone }}</p>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="font-weight-bold">Address</label>
                <p>{{ hub.address }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <a-transfer
            :data-source="products"
            show-search
            :target-keys="hub_products"
            :render="item => item.title"
            @change="productChange"
            @search="handleSearch"
          />

          <a-button v-if="hub_products.length > 0" class="btn btn-success waves-effect waves-light mt-2" :loading="hubProductBtn" @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Save Change</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'hub_product',
  data() {
    return {
      hub: {},
      hub_products: [],
      products: [],
      loader: false,
      flag: false,
      loading: false,
      hubProductBtn: false,
    }
  },
  mounted() {
    this.getProducts()
  },
  methods: {
    getProducts() {
      this.loading = true
      const hubId = this.$route.params.hub_id
      apiClient.get('api/hub/products/' + hubId)
        .then(response => {
          this.loading = false
          if (!response.data.error) {
            const data = response.data
            this.hub = data.hub
            this.products = data.products
            this.hub_products = data.hub_products
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    productChange(hubProducts, direction, moveKeys) {
      this.hub_products = hubProducts
    },
    handleSearch(dir, value) {
      console.log('search:', dir, value)
    },
    save() {
      this.hubProductBtn = true
      const hubId = this.$route.params.hub_id
      apiClient.post('api/hub/products/store/' + hubId, this.hub_products)
        .then(response => {
          this.hubProductBtn = false
          this.$notification.success({
            message: response.data.message,
          })
          this.$router.push({ name: 'hubProduct' })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
<style>
.ant-transfer-list{
  width: 40% !important;
  height: 320px !important;
}
</style>
